import logoImage from "../assets/img/unisot-logo-white-xs.png";
import i18n from "i18next";

const tokenStorage = {
  cookie: "COOKIE",
  localStorage: "LOCAL_STORAGE",
};
let tokenStorageType = tokenStorage.localStorage;

const paymentProvider = {
  stripe: "STRIPE",
  handCash: "HANDCASH",
};
const stripePublicKey = "pk_test_51KiaMfLBK9lX281QZUJK1itROJXimktbzoI8R8JBOQW3wwTyUjbmjDxI301eOHX0dvEJCDVB5TY6651HU0tgcWQE00MiwkU2vY";

const ebsiRegisterFormUrl = "https://app-pilot.ebsi.eu/users-onboarding/v2";

const productName = "UNISOT ID";
const logoText = "UNISOT";
const logoURL = "https://unisot.com";

const profileListOrganizations = true;
const registerListOrganizations = false;
const production = true;
const testDockerBuild = false;
const validateUserDocuments = true;
const useTestnet = false;
const queryElk = false;
const elkEnvironment = "Production";
let showRegisterPage = true;

let frontendURL = "http://localhost:3500";
let baseURL = "http://localhost:4000/api";
const elkURL = "https://elk.unisot.mx/api";
let blockchainInfoURL = "https://whatsonchain.com/tx/{{transactionId}}";
let blockchainAddressInfoURL = "https://whatsonchain.com/address/{{address}}";

if (production) {
  frontendURL = "https://id.unisot.mx";
  baseURL = "https://id.unisot.mx/api";
  showRegisterPage = false;
} else {
  if (testDockerBuild) {
    baseURL = "http://localhost:3500/api";
    tokenStorageType = tokenStorage.localStorage;
  }
}
if (useTestnet) {
  blockchainInfoURL = "https://test.whatsonchain.com/tx/{{transactionId}}";
  blockchainAddressInfoURL =
    "https://test.whatsonchain.com/address/{{address}}";
}

const adLoginURL = `${baseURL}/users/ad/login`;
const openidLoginURL = `${baseURL}/openid/login`;

const messageTimeout = 6000;
const socialMediaLogin = false;
const defaultPageSize = 10;

const version = "1.0.1";

const themes = {
  unisot: "UNISOT",
  ibm: "IBM",
};

const theme = themes.unisot;

const languageTranslations = [
  { id: "en", token: "english" },
  //{ id: "es", token: "spanish" },
  //{ id: "de", token: "german" },
  //{ id: "zh", token: "chinese" }
];

const capabilities = {
  idDashboardView: "id_dashboard_view",
  idOrganizationsView: "id_organizations_view",
  idOrganizationsEdit: "id_organizations_edit",
  idDocumentsView: "id_documents_view",
  idSubscriptionsView: "id_subscriptions_view",
  idBillingsView: "id_billings_view",
  idLookupsView: "id_lookups_view",
  idAuditLogsView: "id_audit_logs_view",
  idKeysView: "id_keys_view",
  idKeysEdit: "id_keys_edit",
  idResolvingPermissionsView: "id_resolving_permissions_view",
  idSSIView: "id_ssi_view",
  idJobsView: "id_jobs_view",
  idPricingView: "id_pricing_view",
  idKeySellingRulesView: "id_marketplace_view",
};

const isCapable = (profile, capability) => {
  return profile?.capabilities?.some((cap) => cap.name === capability);
};

const getFormattedUserName = (userId, userProfile) => {
  return userId !== userProfile._id
    ? `${i18n.t("for")} ${userProfile.name}`
    : "";
};

const capitalize = (input) => {
  if (typeof input !== "string") return "";
  return `${input.charAt(0).toUpperCase()}${input.slice(1).toLowerCase()}`;
};

const methods = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  patch: "PATCH",
};

const contentTypes = {
  json: "application/json",
  formData: "form-data",
  searchParams: "search-parameters",
  fileDownload: "file-download",
};

const urlType = {
  id: "ID SERVICE",
  sdc: "SDC SERVICE",
  bs: "BS SERVICE",
  elk: "ELK SERVICE",
};

const documentTypes = {
  avatar: "AVATAR",
  logo: "LOGO",
  userId: "USER ID",
  driversLicense: "DRIVERS LICENSE",
  companyDocuments: "COMPANY DOCUMENTS",
  signature: "SIGNATURE",
};

const documentCriteria = {
  documentId: "byId",
  documentType: "byType",
};

const keyTypes = {
  id: "ID",
  master: "MASTER",
  create: "CREATE",
  bdi: "BDI",
  addData: "ADDDATA",
  transfer: "TRANSFER",
  consolidate: "CONSOLIDATE",
  funding: "FUNDING",
  merge: "MERGE",
  split: "SPLIT",
  remove: "REMOVE",
  restore: "RESTORE",
  job: "JOB",
  agentInvite: "AGENTINVITE",
  agentJoin: "AGENTJOIN",
  itemGroup: "ITEMGROUP",
  itemGroupAdd: "ITEMGROUPADD",
  itemGroupRemove: "ITEMGROUPREMOVE",
  lookup: "LOOKUP",
  consume: "CONSUME",
  certificate: "CERTIFICATE",
  referenceTag: "REFERENCETAG",
  stateEvent: "STATEEVENT",
};

const keyPermissionTypes = {
  private: "PRIVATE",
  shareable: "SHAREABLE",
  public: "PUBLIC",
};

const parentTypes = {
  user: "USER",
  organization: "ORGANIZATION",
};

const organizationTypes = {
  company: "COMPANY",
  partner: "PARTNER",
};

const organizationRoleTypes = {
  admin: "ADMIN",
  employee: "EMPLOYEE",
  partner: "PARTNER",
  registered: "REGISTERED",
};

const serviceTypes = {
  id: "ID",
  sdc: "SDC",
  soc: "SOC",
  sdd: "SDD",
  ubn: "UBN",
  uts: "UTS",
  uie: "UIE",
  uis: "UIS",
  bs: "BS",
  issf: "ISSF",
  bda: "BDA",
  paymail: "PAYMAIL",
  elk: "ELK",
  topUp: "TOP UP",
};

const capabilityTypes = {
  system: "SYSTEM",
  user: "USER",
};

const roleTypes = {
  system: "SYSTEM",
  user: "USER",
};

const resolvingTypes = {
  native: "native",
  inherited: "inherited",
  assignable: "assignable",
  all: "all",
};

const notificationTypes = {
  enabled: "enabled",
  disabled: "disabled",
};

const lookupTypes = {
  image: "IMAGE",
  url: "URL",
  state: "STATE",
  anchor: "ANCHOR",
  template: "TEMPLATE",
  key: "KEY",
};

const lookupPermissionTypes = {
  private: "PRIVATE",
  public: "PUBLIC",
};

const serviceSubscriptionTypes = {
  id: "ID",
  sdc: "SDC",
  soc: "SOC",
  sdd: "SDD",
  ubn: "UBN",
  uts: "UTS",
  uie: "UIE",
  uis: "UIS",
  bs: "BS",
  issf: "ISSF",
  bda: "BDA",
  paymail: "PAYMAIL",
  elk: "ELK",
  topUp: "TOP UP",
};

const serviceSubscriptionPlans = {
  operation: "PER OPERATION",
  monthly: "MONTHLY",
  yearly: "YEARLY",
};

const serviceSubscriptionPaymentMethods = {
  invoice: "INVOICE",
  creditCard: "CREDIT CARD",
  wallet: "WALLET",
};

const serviceSubscriptionStatuses = {
  active: "ACTIVE",
  inactive: "INACTIVE",
  cancelled: "CANCELLED",
};

const billingStatuses = {
  paid: "PAID",
  refunded: "REFUNDED",
  pending: "PENDING",
  pendingTopUp: "PENDING_TOP_UP",
  cancelled: "CANCELLED",
};

const selfSovereignIdentityProviders = {
  ebsi: "EBSI",
  bsv: "BSV",
};

const jobTransactionStatus = {
  prepared: "PREPARED",
  written: "WRITTEN",
};

const selfSovereignIdentityTypes = {
  client: "CLIENT",
  verifiableAuthorization: "VERIFIABLE_AUTHORIZATION",
  verifiableCredential: "VERIFIABLE_CREDENTIAL",
  verifiablePresentation: "VERIFIABLE_PRESENTATION",
  did: "DID",
};

const defaultRegistrationServices = [
  {
    serviceType: serviceTypes.sdc,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [
      {
        name: serviceTypes.sdc,
        generateKeys: true,
      },
    ],
  },
  {
    serviceType: serviceTypes.topUp,
    subscriptionPlan: serviceSubscriptionPlans.operation,
    endpoints: [],
  },
];
const defaultRegistrationRoles = ["id_user", "sdc_user"];

const exactMatch = [
  "parentId",
  "fileSize",
  "_id",
  "shareableFormatted",
  "deploymentId",
];

const endpoints = {
  monitorHealth: {
    method: methods.get,
    path: "monitor/health",
    status: 200,
    urlType: urlType.id,
  },
  getPublicSystemConfig: {
    method: methods.get,
    path: "public/config",
    status: 200,
    urlType: urlType.id,
  },
  createUser: {
    method: methods.post,
    path: "users/profile",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  createUserSuperuser: {
    method: methods.post,
    path: "users/superuser/profile",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUser: {
    method: methods.patch,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserMetadata: {
    method: methods.patch,
    path: "users/profile/metadata",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUser: {
    method: methods.delete,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  readUser: {
    method: methods.get,
    path: "users/profile",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  readUserTokensSuperuser: {
    method: methods.get,
    path: "users/superuser/tokens",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  generateAuthTokenSuperuser: {
    method: methods.patch,
    path: "users/superuser/profile/token",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  loginUser: {
    method: methods.post,
    path: "users/login",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  magicLinkLogin: {
    method: methods.post,
    path: "users/magicLink/{{userId}}/{{magicLinkSecret}}",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
    populate: true,
  },
  logoutUser: {
    method: methods.post,
    path: "users/logout",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  logoutUserAllSessions: {
    method: methods.post,
    path: "users/logoutAll",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getAllUsers: {
    method: methods.get,
    path: "users/listAll",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPublicShareUser: {
    method: methods.get,
    path: "users/publicShare",
    status: 200,
    urlType: urlType.id,
  },
  getAvailableOrganizations: {
    method: methods.get,
    path: "users/availableOrganizations",
    status: 200,
    urlType: urlType.id,
  },
  getAvailableNotifications: {
    method: methods.get,
    path: "users/availableNotifications",
    status: 200,
    urlType: urlType.id,
  },
  sendUserValidateEmailLink: {
    method: methods.get,
    path: "users/validateEmail/sendLink",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  userValidateEmail: {
    method: methods.get,
    path: "users/validateEmail/{{parentId}}/{{parentType}}/{{emailValidationSecret}}",
    status: 200,
    populate: true,
    urlType: urlType.id,
  },
  sendUserResetPasswordEmailLink: {
    method: methods.post,
    path: "users/resetPassword/sendLink",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  resetUserPassword: {
    method: methods.patch,
    path: "users/resetPassword/{{userId}}/{{passwordResetSecret}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.id,
  },
  sendUserLoginMagicLink: {
    method: methods.post,
    path: "users/magicLink/sendLink",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserCredits: {
    method: methods.patch,
    path: "users/superuser/profile/credits",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserBlockedFlag: {
    method: methods.patch,
    path: "users/superuser/profile/blocked",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  requestUserDocumentsValidation: {
    method: methods.patch,
    path: "users/profile/requestDocumentsValidation",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserDocumentsValidation: {
    method: methods.patch,
    path: "users/superuser/profile/validateDocuments",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserEmailValidation: {
    method: methods.patch,
    path: "users/superuser/profile/validateEmail",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertUserImage: {
    method: methods.post,
    path: "users/documents/image",
    status: 201,
    contentType: contentTypes.formData,
    urlType: urlType.id,
  },
  upsertUserFile: {
    method: methods.post,
    path: "users/documents/file",
    status: 201,
    contentType: contentTypes.formData,
    urlType: urlType.id,
  },
  getUserDocuments: {
    method: methods.get,
    path: "users/documents",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  readUserDocument: {
    method: methods.get,
    path: "users/documents/{{documentCriteria}}/base64",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.id,
  },
  downloadUserDocument: {
    method: methods.get,
    path: "users/documents/{{documentCriteria}}/raw",
    status: 200,
    contentType: contentTypes.fileDownload,
    populate: true,
    urlType: urlType.id,
  },
  deleteUserDocument: {
    method: methods.delete,
    path: "users/documents/{{documentCriteria}}",
    status: 200,
    contentType: contentTypes.json,
    populate: true,
    urlType: urlType.id,
  },
  getDeployments: {
    method: methods.get,
    path: "users/superuser/deployments",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getSingleDeployment: {
    method: methods.get,
    path: "users/superuser/deployments/{{deploymentId}}",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  upsertDeployment: {
    method: methods.post,
    path: "users/superuser/deployments",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteDeployment: {
    method: methods.delete,
    path: "users/superuser/deployments",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  topUpDeployment: {
    method: methods.post,
    path: "users/superuser/deployments/topUp",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getCapabilities: {
    method: methods.get,
    path: "users/superuser/capabilities",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getSingleCapability: {
    method: methods.get,
    path: "users/superuser/capabilities/{{capabilityId}}",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getRoleCapabilities: {
    method: methods.get,
    path: "users/superuser/roles/capabilities/{{resolvingType}}",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  updateSingleCapability: {
    method: methods.patch,
    path: "users/superuser/capabilities",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteCapability: {
    method: methods.delete,
    path: "users/superuser/capabilities",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertCapability: {
    method: methods.post,
    path: "users/superuser/capabilities",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getRoles: {
    method: methods.get,
    path: "users/superuser/roles",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getSingleRole: {
    method: methods.get,
    path: "users/superuser/roles/{{roleId}}",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getRoleInheritedRoles: {
    method: methods.get,
    path: "users/superuser/roles/inheritedRoles/{{resolvingType}}",
    status: 200,
    populate: true,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  updateSingleRole: {
    method: methods.patch,
    path: "users/superuser/roles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteRole: {
    method: methods.delete,
    path: "users/superuser/roles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertRole: {
    method: methods.post,
    path: "users/superuser/roles",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  cloneRole: {
    method: methods.post,
    path: "users/superuser/roles/clone",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addRoleCapabilities: {
    method: methods.post,
    path: "users/superuser/roles/capabilities",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteRoleCapabilities: {
    method: methods.delete,
    path: "users/superuser/roles/capabilities",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addInheritedRoles: {
    method: methods.post,
    path: "users/superuser/roles/inheritedRoles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteInheritedRoles: {
    method: methods.delete,
    path: "users/superuser/roles/inheritedRoles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addUserRoles: {
    method: methods.post,
    path: "users/superuser/profile/roles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserRoles: {
    method: methods.delete,
    path: "users/superuser/profile/roles",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserNotifications: {
    method: methods.patch,
    path: "users/profile/disableNotifications",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateOrganizationNotifications: {
    method: methods.patch,
    path: "users/organizations/disableNotifications",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserServices: {
    method: methods.get,
    path: "users/services",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  upsertUserService: {
    method: methods.post,
    path: "users/superuser/services",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserService: {
    method: methods.delete,
    path: "users/superuser/services",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserServiceBlockedFlag: {
    method: methods.patch,
    path: "users/superuser/services/blocked",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserServiceEndpoints: {
    method: methods.get,
    path: "users/endpoints",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  upsertUserServiceEndpoint: {
    method: methods.post,
    path: "users/superuser/endpoints",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateUserServiceEndpointBlockedFlag: {
    method: methods.patch,
    path: "users/superuser/endpoints/blocked",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserServiceEndpoint: {
    method: methods.delete,
    path: "users/superuser/endpoints",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getSingleUserKey: {
    method: methods.get,
    path: "users/superuser/keys/single/{{keyCriteria}}",
    status: 200,
    contentType: contentTypes.searchParams,
    populate: true,
    urlType: urlType.id,
  },
  getDeploymentConfig: {
    method: methods.get,
    path: "users/superuser/keys/deployment/config",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getUserKeys: {
    method: methods.get,
    path: "users/keys",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadUserKeys: {
    method: methods.get,
    path: "users/keys",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  calculateKeysStatistics: {
    method: methods.post,
    path: "users/keys/statistics",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addUserKey: {
    method: methods.post,
    path: "users/keys",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteSingleUserKey: {
    method: methods.delete,
    path: "users/keys/single",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserKeyTree: {
    method: methods.delete,
    path: "users/keys/tree",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  generate2FASecret: {
    method: methods.patch,
    path: "users/profile/generate2FASecret",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  enable2FA: {
    method: methods.patch,
    path: "users/profile/enable2FA",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  disable2FA: {
    method: methods.patch,
    path: "users/profile/disable2FA",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserLookups: {
    method: methods.get,
    path: "users/lookups",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadUserLookups: {
    method: methods.get,
    path: "users/lookups",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  getSingleUserLookup: {
    method: methods.get,
    path: "users/lookups/single",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  deleteUserLookup: {
    method: methods.delete,
    path: "users/lookups/single",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertUserLookup: {
    method: methods.post,
    path: "users/lookups",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getAllSubscriptions: {
    method: methods.get,
    path: "users/superuser/subscriptions",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadAllSubscriptions: {
    method: methods.get,
    path: "users/superuser/subscriptions",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  getUserSubscriptions: {
    method: methods.get,
    path: "users/subscriptions",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  cancelUserSubscription: {
    method: methods.patch,
    path: "users/subscriptions/cancel",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserSubscription: {
    method: methods.delete,
    path: "users/superuser/subscriptions",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertUserSubscription: {
    method: methods.post,
    path: "users/superuser/subscriptions",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserBillings: {
    method: methods.get,
    path: "users/billings",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getSingleUserBilling: {
    method: methods.get,
    path: "users/billings/single",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  updateUserBillingStatus: {
    method: methods.patch,
    path: "users/superuser/billings/status",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteUserBilling: {
    method: methods.delete,
    path: "users/superuser/billings",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addUserBilling: {
    method: methods.post,
    path: "users/superuser/billings",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getBillingPaymentSession: {
    method: methods.get,
    path: "users/billings/payment/session",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadBillingDocument: {
    method: methods.get,
    path: "users/billings/report",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  sendEmailBillingDocument: {
    method: methods.get,
    path: "users/billings/report/email",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getPricingPlans: {
    method: methods.get,
    path: "users/pricingPlans",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  deletePricingPlan: {
    method: methods.delete,
    path: "users/superuser/pricingPlans",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  upsertPricingPlan: {
    method: methods.post,
    path: "users/superuser/pricingPlans",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addPricingPlanSubscription: {
    method: methods.post,
    path: "users/superuser/pricingPlans/subscriptions",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deletePricingPlanSubscription: {
    method: methods.delete,
    path: "users/superuser/pricingPlans/subscriptions",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  requestPricingPlanUpdate: {
    method: methods.patch,
    path: "users/profile/requestPricingPlan",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  activatePricingPlan: {
    method: methods.post,
    path: "users/superuser/pricingPlans/activate",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getPricingLists: {
    method: methods.get,
    path: "users/superuser/pricingPlans/pricingLists",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  updatePricingLists: {
    method: methods.patch,
    path: "users/superuser/pricing",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getRawDID: {
    method: methods.post,
    path: "users/keys/did/raw",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  createOrganizationSuperuser: {
    method: methods.post,
    path: "users/superuser/organizations",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateOrganizationSuperuser: {
    method: methods.patch,
    path: "users/superuser/organizations",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateOrganizationAttributes: {
    method: methods.patch,
    path: "users/organizations/attributes",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateOrganizationMetadataSuperuser: {
    method: methods.patch,
    path: "users/superuser/organizations/metadata",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteOrganizationSuperuser: {
    method: methods.delete,
    path: "users/superuser/organizations/single",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  deleteOrganizationUser: {
    method: methods.delete,
    path: "users/organizations/user",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addOrganizationUser: {
    method: methods.post,
    path: "users/organizations/user",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getUserJobsCount: {
    method: methods.get,
    path: "job/count",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getUserJobs: {
    method: methods.get,
    path: "job/filter",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  elkSearch: {
    method: methods.post,
    path: `elk/statistics?env=${elkEnvironment}`,
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.elk,
  },
  getSystemOverview: {
    method: methods.get,
    path: "superuser/systemOverview",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  getUserAuditLogs: {
    method: methods.get,
    path: "users/auditLogs",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadUserAuditLogs: {
    method: methods.get,
    path: "users/auditLogs",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  startBillingTopUpPaymentSessionId: {
    method: methods.post,
    path: "users/billings/payment/topUp/session",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  sdcQueryStatistics: {
    method: methods.get,
    path: "",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.sdc,
  },
  sdcAggregateStatistics: {
    method: methods.post,
    path: "",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.sdc,
  },
  bsQueryTransactionStatistics: {
    method: methods.post,
    path: "",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.bs,
  },
  getUserKeySellingRules: {
    method: methods.get,
    path: "users/keySellingRules",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadUserKeySellingRules: {
    method: methods.get,
    path: "users/keySellingRules",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  deleteKeySellingRule: {
    method: methods.delete,
    path: "users/keySellingRules",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  addKeySellingRule: {
    method: methods.post,
    path: "users/keySellingRules",
    status: 201,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  updateKeySellingRule: {
    method: methods.patch,
    path: "users/keySellingRules",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  reloadSystemConfiguration: {
    method: methods.get,
    path: "config/superuser/reload",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  readSystemConfiguration: {
    method: methods.get,
    path: "config/superuser/read",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  updateSystemConfiguration: {
    method: methods.post,
    path: "config/superuser/update",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  shutdownSystemService: {
    method: methods.patch,
    path: "config/superuser/shutdown",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  checkValidVerifiableAuthorization: {
    method: methods.get,
    path: "users/ssi/checkValidVA",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  acquireVerifiableAuthorization: {
    method: methods.post,
    path: "users/ssi/acquireVA",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  registerDidDocument: {
    method: methods.post,
    path: "users/ssi/registerDIDdocument",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getSingleUserSSIEntry: {
    method: methods.get,
    path: "users/ssi/single",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
  downloadSingleUserSSIEntry: {
    method: methods.get,
    path: "users/ssi/single",
    status: 200,
    contentType: contentTypes.fileDownload,
    urlType: urlType.id,
  },
  deleteSingleUserSSIEntry: {
    method: methods.delete,
    path: "users/ssi/single",
    status: 200,
    contentType: contentTypes.json,
    urlType: urlType.id,
  },
  getSSIUserEntries: {
    method: methods.get,
    path: "/users/ssi",
    status: 200,
    contentType: contentTypes.searchParams,
    urlType: urlType.id,
  },
};
const authenticationViewPath = "/auth";
const userProfileViewPath = "/admin/user-profile";

const localStorageSessionTokenKey = "userIdToken";

const loginRedirectUri = ({
  endpoints = undefined,
  deploymentId = undefined,
  redirectPath = undefined,
} = {}) => {
  const response = {
    local: true,
    path: "/",
  };
  if (!Array.isArray(endpoints) || !deploymentId) {
    return response;
  }
  const endpoint = endpoints.find(
    (entry) => entry.deploymentId === deploymentId && !entry.blocked
  );
  if (!endpoint) {
    return response;
  }
  let redirectUri = endpoint.uri;
  redirectUri = redirectUri.endsWith("/") ? redirectUri : `${redirectUri}/`;
  redirectUri = `${redirectUri}auth/redirect-page`;
  const queryParams = {};
  let paramSet = false;
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
      paramSet = true;
    }
  }
  if (redirectPath) {
    queryParams.redirectPath = redirectPath;
    paramSet = true;
  }
  if (paramSet) {
    const formattedQueryParams = new URLSearchParams(queryParams).toString();
    redirectUri = `${redirectUri}?${formattedQueryParams}`;
  }
  response.local = false;
  response.path = redirectUri;
  return response;
};

const getRedirectUri = (
  uri,
  serviceType = undefined,
  endpointName = undefined,
  userId = undefined,
  superuser = false
) => {
  let redirectUri = uri;
  const queryParams = {};
  let paramSet = false;
  if ([serviceTypes.sdc, serviceTypes.ubn].includes(serviceType)) {
    redirectUri = uri.endsWith("/") ? uri : `${uri}/`;
    redirectUri = `${redirectUri}auth/redirect-page`;
    if (serviceType === serviceTypes.ubn && endpointName) {
      queryParams.name = endpointName;
      paramSet = true;
    }
  }
  if (superuser && userId) {
    queryParams.userId = userId;
    paramSet = true;
  }
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
      paramSet = true;
    }
  }
  if (paramSet) {
    const formattedQueryParams = new URLSearchParams(queryParams).toString();
    redirectUri = `${redirectUri}?${formattedQueryParams}`;
  }
  return redirectUri;
};

const getSessionToken = () => {
  const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
  if (sessionToken) {
    return sessionToken;
  }
  throw new Error(i18n.t("session-token-does-not-exist"));
};

const storeSessionToken = (token) => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.setItem(localStorageSessionTokenKey, token);
  }
};

const removeSessionToken = () => {
  if (tokenStorageType === tokenStorage.localStorage) {
    localStorage.removeItem(localStorageSessionTokenKey);
  }
};

const populatePath = (urlPath, params) => {
  let populatedPath = urlPath;
  const processingParameters = Object.entries(params);
  const paramsToProcess = {};
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of processingParameters) {
    const token = `{{${key}}}`;
    if (urlPath.includes(token)) {
      populatedPath = populatedPath.split(token).join(value);
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    populatedPath,
    paramsToProcess,
  };
};

const splitParamFields = (params, fields) => {
  const paramsFields = {};
  const paramsToProcess = {};
  const processingParameters = Object.entries(params);
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of processingParameters) {
    if (fields.includes(key)) {
      paramsFields[key] = value;
    } else {
      paramsToProcess[key] = value;
    }
  }
  return {
    paramsFields,
    paramsToProcess,
  };
};

const apiRequest = async (
  endpoint,
  signal = undefined,
  params = {},
  authorize = true,
  exportSessionToken = false,
  overrideUrl = undefined
) => {
  const { method, path, status, populate } = endpoint;
  let populatedPath = path;
  let paramsToProcess = params;
  if (populate) {
    const pathPopulatingResult = populatePath(path, params);
    populatedPath = pathPopulatingResult.populatedPath;
    paramsToProcess = pathPopulatingResult.paramsToProcess;
  }

  // Construct API URL
  const serviceUrl = endpoint.urlType === urlType.elk ? elkURL : baseURL;
  const url = new URL(
    overrideUrl ? overrideUrl : `${serviceUrl}/${populatedPath}`
  );

  // Set method
  const request = {
    method,
  };
  if (signal) {
    request.signal = signal;
  }

  // Set headers
  const headers = {};
  if (endpoint.contentType) {
    if (endpoint.contentType === contentTypes.json) {
      headers["Content-Type"] = endpoint.contentType;
      request.body = JSON.stringify(paramsToProcess);
    } else if (endpoint.contentType === contentTypes.formData) {
      const formData = new FormData();
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(paramsToProcess)) {
        formData.append(key, value);
      }
      request.body = formData;
    } else if (
      endpoint.contentType === contentTypes.searchParams ||
      endpoint.contentType === contentTypes.fileDownload
    ) {
      url.search = new URLSearchParams(paramsToProcess).toString();
    }
  }
  if (tokenStorageType === tokenStorage.cookie) {
    request.credentials = "include";
  } else if (tokenStorageType === tokenStorage.localStorage) {
    if (authorize) {
      const sessionToken = getSessionToken();
      headers["authorization"] = `Bearer ${sessionToken}`;
    }
  }
  if (Object.keys(headers).length > 0) {
    request.headers = headers;
  }

  const response = await fetch(url, request);
  let responseData;
  if (
    response.status === status &&
    endpoint.contentType === contentTypes.fileDownload
  ) {
    responseData = await response.blob();
  } else {
    responseData = await response.json();
  }
  if (response.status !== status) {
    throw new Error(responseData.message);
  }
  if (exportSessionToken) {
    storeSessionToken(responseData.token);
  }
  return responseData;
};

const isUserValidated = (userProfile, validateSuperuser = false) => {
  if (validateSuperuser) return userProfile.superuser;
  return (
    !userProfile.blocked &&
    userProfile.validatedEmail &&
    (!validateUserDocuments || userProfile.validatedDocuments)
  );
};

const getRedirectPath = (sourceURL, redirectPath) => {
  const redirectUri = `${sourceURL}/auth/redirect-page`;
  const queryParams = {
    redirectPath,
  };
  if (tokenStorageType === tokenStorage.localStorage) {
    const sessionToken = localStorage.getItem(localStorageSessionTokenKey);
    if (sessionToken) {
      queryParams.authToken = sessionToken;
    }
  }
  const formattedQueryParams = new URLSearchParams(queryParams).toString();
  return `${redirectUri}?${formattedQueryParams}`;
};

const loginRequired = async (
  signal,
  redirectNotValidated,
  validateSuperuser
) => {
  try {
    const userProfile = await apiRequest(endpoints.readUser, signal);
    if (
      redirectNotValidated &&
      !isUserValidated(userProfile, validateSuperuser)
    ) {
      window.location.replace(
        getRedirectPath(frontendURL, userProfileViewPath)
      );
    }
    return userProfile;
  } catch (e) {
    removeSessionToken();
    window.location.replace(authenticationViewPath);
  }
};

const isObjectEmpty = (obj) => {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
};

const healthCheck = async (url, signal) => {
  return await apiRequest(
    {
      method: methods.get,
      path: "",
      status: 200,
      contentType: contentTypes.searchParams,
      urlType: urlType.id,
    },
    signal,
    {},
    false,
    false,
    url
  );
};

const ApiService = {
  stripePublicKey,
  ebsiRegisterFormUrl,
  paymentProvider,
  profileListOrganizations,
  registerListOrganizations,
  queryElk,
  capitalize,
  storeSessionToken,
  loginRedirectUri,
  getRedirectUri,
  frontendURL,
  populatePath,
  blockchainInfoURL,
  blockchainAddressInfoURL,
  productName,
  logoText,
  logoImage,
  logoURL,
  messageTimeout,
  getFormattedUserName,
  socialMediaLogin,
  defaultPageSize,
  validateUserDocuments,
  documentTypes,
  documentCriteria,
  keyTypes,
  keyPermissionTypes,
  organizationTypes,
  organizationRoleTypes,
  parentTypes,
  serviceTypes,
  capabilityTypes,
  roleTypes,
  resolvingTypes,
  notificationTypes,
  lookupTypes,
  lookupPermissionTypes,
  serviceSubscriptionTypes,
  serviceSubscriptionPlans,
  serviceSubscriptionPaymentMethods,
  serviceSubscriptionStatuses,
  billingStatuses,
  selfSovereignIdentityProviders,
  jobTransactionStatus,
  selfSovereignIdentityTypes,
  defaultRegistrationServices,
  defaultRegistrationRoles,
  exactMatch,
  isUserValidated,
  languageTranslations,
  showRegisterPage,
  adLoginURL,
  openidLoginURL,
  formatDateTime: (input) => {
    if (!input) {
      return i18n.t("forever");
    }
    return input.replace(/[TZ]/g, " ").trim();
  },
  monitorHealth: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.monitorHealth,
      signal,
      params,
      false
    );
    return responseData;
  },
  getPublicSystemConfig: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPublicSystemConfig,
      signal,
      params,
      false
    );
    return responseData;
  },
  createUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.createUser,
      signal,
      params,
      false,
      true
    );
    return responseData;
  },
  createUserSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.createUserSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  updateUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  updateUserMetadata: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserMetadata,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  deleteUser: async (params, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, [
      "superuser",
    ]);
    const responseData = await apiRequest(
      endpoints.deleteUser,
      signal,
      paramsToProcess
    );
    if (!paramsFields.superuser) {
      removeSessionToken();
    }
    return responseData;
  },
  readUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.readUser, signal, params);
    return responseData;
  },
  readUserTokensSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.readUserTokensSuperuser,
      signal,
      params
    );
    return responseData;
  },
  generateAuthTokenSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.generateAuthTokenSuperuser,
      signal,
      params
    );
    return responseData;
  },
  loginRequired: async (
    signal = undefined,
    redirectNotValidated = true,
    validateSuperuser = false
  ) => {
    const responseData = await loginRequired(
      signal,
      redirectNotValidated,
      validateSuperuser
    );
    return responseData;
  },
  loginUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.loginUser,
      signal,
      params,
      false,
      true
    );
    return responseData;
  },
  magicLinkLogin: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.magicLinkLogin,
      signal,
      params,
      false,
      true
    );
    return responseData;
  },
  logoutUser: async (params, history, redirect = true, signal = undefined) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, [
      "superuser",
    ]);
    const responseData = await apiRequest(
      endpoints.logoutUser,
      signal,
      paramsToProcess
    );
    if (!paramsFields.superuser) {
      removeSessionToken();
      if (redirect) {
        history.push("/");
      }
    }
    return responseData;
  },
  logoutUserAllSessions: async (
    params,
    history,
    redirect = true,
    signal = undefined
  ) => {
    const { paramsFields, paramsToProcess } = splitParamFields(params, [
      "superuser",
    ]);
    const responseData = await apiRequest(
      endpoints.logoutUserAllSessions,
      signal,
      paramsToProcess
    );
    if (!paramsFields.superuser) {
      removeSessionToken();
      if (redirect) {
        history.push("/");
      }
    }
    return responseData;
  },
  getAllUsers: async (
    params,
    signal = undefined,
    filterOnlyValidatedUsers = false,
    filterOutSpecialUsers = false,
    filterOutSuperUsers = false
  ) => {
    const responseData = await apiRequest(
      endpoints.getAllUsers,
      signal,
      params
    );
    let filteredEntries = responseData;
    if (filterOnlyValidatedUsers) {
      filteredEntries = filteredEntries.filter((entry) =>
        isUserValidated(entry)
      );
    }
    if (filterOutSpecialUsers) {
      filteredEntries = filteredEntries.filter((entry) => !entry.special);
    }
    if (filterOutSuperUsers) {
      filteredEntries = filteredEntries.filter((entry) => !entry.superuser);
    }
    return filteredEntries;
  },
  getPublicShareUser: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getPublicShareUser,
      signal,
      params,
      false
    );
    return responseData;
  },
  getAvailableOrganizations: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getAvailableOrganizations,
      signal,
      params,
      false
    );
    return responseData;
  },
  getAvailableNotifications: async (signal = undefined) => {
    const params = {};
    const responseData = await apiRequest(
      endpoints.getAvailableNotifications,
      signal,
      params
    );
    return responseData;
  },
  sendUserValidateEmailLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sendUserValidateEmailLink,
      signal,
      params
    );
    return responseData;
  },
  userValidateEmail: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.userValidateEmail,
      signal,
      params,
      false
    );
    return responseData;
  },
  sendUserResetPasswordEmailLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sendUserResetPasswordEmailLink,
      signal,
      params,
      false
    );
    return responseData;
  },
  sendUserLoginMagicLink: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sendUserLoginMagicLink,
      signal,
      params,
      false
    );
    return responseData;
  },
  resetUserPassword: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.resetUserPassword,
      signal,
      params,
      false
    );
    return responseData;
  },
  updateUserCredits: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserCredits,
      signal,
      params
    );
    return responseData;
  },
  updateUserBlockedFlag: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserBlockedFlag,
      signal,
      params
    );
    return responseData;
  },
  requestUserDocumentsValidation: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.requestUserDocumentsValidation,
      signal,
      params
    );
    return responseData;
  },
  updateUserDocumentsValidation: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserDocumentsValidation,
      signal,
      params
    );
    return responseData;
  },
  updateUserEmailValidation: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserEmailValidation,
      signal,
      params
    );
    return responseData;
  },
  upsertUserImage: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserImage,
      signal,
      params
    );
    return responseData;
  },
  upsertUserFile: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserFile,
      signal,
      params
    );
    return responseData;
  },
  getUserDocuments: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserDocuments,
      signal,
      params
    );
    return responseData;
  },
  readUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.readUserDocument,
      signal,
      params
    );
    return responseData;
  },
  downloadUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadUserDocument,
      signal,
      params
    );
    return responseData;
  },
  deleteUserDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserDocument,
      signal,
      params
    );
    return responseData;
  },
  getDeployments: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDeployments,
      signal,
      params
    );
    return responseData;
  },
  getSingleDeployment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleDeployment,
      signal,
      params
    );
    return responseData;
  },
  upsertDeployment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertDeployment,
      signal,
      params
    );
    return responseData;
  },
  deleteDeployment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteDeployment,
      signal,
      params
    );
    return responseData;
  },
  topUpDeployment: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.topUpDeployment,
      signal,
      params
    );
    return responseData;
  },
  getCapabilities: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getCapabilities,
      signal,
      params
    );
    return responseData;
  },
  getSingleCapability: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleCapability,
      signal,
      params
    );
    return responseData;
  },
  getRoleCapabilities: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getRoleCapabilities,
      signal,
      params
    );
    return responseData;
  },
  updateSingleCapability: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateSingleCapability,
      signal,
      params
    );
    return responseData;
  },
  deleteCapability: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteCapability,
      signal,
      params
    );
    return responseData;
  },
  upsertCapability: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertCapability,
      signal,
      params
    );
    return responseData;
  },
  getRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getRoles, signal, params);
    return responseData;
  },
  getSingleRole: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleRole,
      signal,
      params
    );
    return responseData;
  },
  getRoleInheritedRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getRoleInheritedRoles,
      signal,
      params
    );
    return responseData;
  },
  updateSingleRole: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateSingleRole,
      signal,
      params
    );
    return responseData;
  },
  deleteRole: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.deleteRole, signal, params);
    return responseData;
  },
  upsertRole: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.upsertRole, signal, params);
    return responseData;
  },
  cloneRole: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.cloneRole, signal, params);
    return responseData;
  },
  addRoleCapabilities: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addRoleCapabilities,
      signal,
      params
    );
    return responseData;
  },
  deleteRoleCapabilities: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteRoleCapabilities,
      signal,
      params
    );
    return responseData;
  },
  addInheritedRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addInheritedRoles,
      signal,
      params
    );
    return responseData;
  },
  deleteInheritedRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteInheritedRoles,
      signal,
      params
    );
    return responseData;
  },
  addUserRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addUserRoles,
      signal,
      params
    );
    return responseData;
  },
  deleteUserRoles: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserRoles,
      signal,
      params
    );
    return responseData;
  },
  updateUserNotifications: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserNotifications,
      signal,
      params
    );
    return responseData;
  },
  updateOrganizationNotifications: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateOrganizationNotifications,
      signal,
      params
    );
    return responseData;
  },
  getUserServices: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserServices,
      signal,
      params
    );
    return responseData;
  },
  upsertUserService: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserService,
      signal,
      params
    );
    return responseData;
  },
  deleteUserService: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserService,
      signal,
      params
    );
    return responseData;
  },
  updateUserServiceBlockedFlag: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserServiceBlockedFlag,
      signal,
      params
    );
    return responseData;
  },
  getUserServiceEndpoints: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserServiceEndpoints,
      signal,
      params
    );
    return responseData;
  },
  upsertUserServiceEndpoint: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserServiceEndpoint,
      signal,
      params
    );
    return responseData;
  },
  updateUserServiceEndpointBlockedFlag: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserServiceEndpointBlockedFlag,
      signal,
      params
    );
    return responseData;
  },
  deleteUserServiceEndpoint: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserServiceEndpoint,
      signal,
      params
    );
    return responseData;
  },
  getSingleUserKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleUserKey,
      signal,
      params
    );
    return responseData;
  },
  getDeploymentConfig: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getDeploymentConfig,
      signal,
      params
    );
    return responseData;
  },
  getUserKeys: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserKeys,
      signal,
      params
    );
    return responseData;
  },
  downloadUserKeys: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadUserKeys,
      signal,
      params
    );
    return responseData;
  },
  calculateKeysStatistics: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.calculateKeysStatistics,
      signal,
      params
    );
    return responseData;
  },
  addUserKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.addUserKey, signal, params);
    return responseData;
  },
  deleteSingleUserKey: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteSingleUserKey,
      signal,
      params
    );
    return responseData;
  },
  deleteUserKeyTree: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserKeyTree,
      signal,
      params
    );
    return responseData;
  },
  generate2FASecret: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.generate2FASecret,
      signal,
      params
    );
    return responseData;
  },
  enable2FA: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.enable2FA, signal, params);
    return responseData;
  },
  disable2FA: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.disable2FA, signal, params);
    return responseData;
  },
  getUserLookups: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserLookups,
      signal,
      params
    );
    return responseData;
  },
  downloadUserLookups: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadUserLookups,
      signal,
      params
    );
    return responseData;
  },
  getSingleUserLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleUserLookup,
      signal,
      params
    );
    return responseData;
  },
  deleteUserLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserLookup,
      signal,
      params
    );
    return responseData;
  },
  upsertUserLookup: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserLookup,
      signal,
      params
    );
    return responseData;
  },
  getAllSubscriptions: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getAllSubscriptions,
      signal,
      params
    );
    return responseData;
  },
  downloadAllSubscriptions: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadAllSubscriptions,
      signal,
      params
    );
    return responseData;
  },
  getUserSubscriptions: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserSubscriptions,
      signal,
      params
    );
    return responseData;
  },
  cancelUserSubscription: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.cancelUserSubscription,
      signal,
      params
    );
    return responseData;
  },
  deleteUserSubscription: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserSubscription,
      signal,
      params
    );
    return responseData;
  },
  upsertUserSubscription: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertUserSubscription,
      signal,
      params
    );
    return responseData;
  },
  getUserBillings: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserBillings,
      signal,
      params
    );
    return responseData;
  },
  getSingleUserBilling: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleUserBilling,
      signal,
      params
    );
    return responseData;
  },
  updateUserBillingStatus: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateUserBillingStatus,
      signal,
      params
    );
    return responseData;
  },
  deleteUserBilling: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteUserBilling,
      signal,
      params
    );
    return responseData;
  },
  addUserBilling: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addUserBilling,
      signal,
      params
    );
    return responseData;
  },
  getBillingPaymentSession: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getBillingPaymentSession,
      signal,
      params
    );
    return responseData;
  },
  downloadBillingDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadBillingDocument,
      signal,
      params
    );
    return responseData;
  },
  sendEmailBillingDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sendEmailBillingDocument,
      signal,
      params
    );
    return responseData;
  },
  getPricingPlans: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPricingPlans,
      signal,
      params,
      false
    );
    return responseData;
  },
  deletePricingPlan: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deletePricingPlan,
      signal,
      params
    );
    return responseData;
  },
  upsertPricingPlan: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.upsertPricingPlan,
      signal,
      params
    );
    return responseData;
  },
  addPricingPlanSubscription: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addPricingPlanSubscription,
      signal,
      params
    );
    return responseData;
  },
  deletePricingPlanSubscription: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deletePricingPlanSubscription,
      signal,
      params
    );
    return responseData;
  },
  requestPricingPlanUpdate: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.requestPricingPlanUpdate,
      signal,
      params
    );
    return responseData;
  },
  activatePricingPlan: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.activatePricingPlan,
      signal,
      params
    );
    return responseData;
  },
  getPricingLists: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getPricingLists,
      signal,
      params
    );
    return responseData;
  },
  updatePricingLists: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updatePricingLists,
      signal,
      params
    );
    return responseData;
  },
  getRawDID: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.getRawDID, signal, params);
    return responseData;
  },
  createOrganizationSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.createOrganizationSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  updateOrganizationSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateOrganizationSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  updateOrganizationAttributes: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateOrganizationAttributes,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  updateOrganizationMetadataSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateOrganizationMetadataSuperuser,
      signal,
      params,
      true,
      false
    );
    return responseData;
  },
  deleteOrganizationSuperuser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteOrganizationSuperuser,
      signal,
      params
    );
    return responseData;
  },
  deleteOrganizationUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteOrganizationUser,
      signal,
      params
    );
    return responseData;
  },
  addOrganizationUser: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addOrganizationUser,
      signal,
      params
    );
    return responseData;
  },
  getUserJobsCount: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserJobsCount,
      signal,
      params
    );
    return responseData;
  },
  getUserJobs: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserJobs,
      signal,
      params
    );
    return responseData;
  },
  elkSearch: async (params, signal = undefined) => {
    const responseData = await apiRequest(endpoints.elkSearch, signal, params);
    return responseData;
  },
  getSystemOverview: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSystemOverview,
      signal,
      params
    );
    return responseData;
  },
  getUserAuditLogs: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserAuditLogs,
      signal,
      params
    );
    return responseData;
  },
  downloadUserAuditLogs: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadUserAuditLogs,
      signal,
      params
    );
    return responseData;
  },
  startBillingTopUpPaymentSessionId: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.startBillingTopUpPaymentSessionId,
      signal,
      params
    );
    return responseData;
  },
  sdcQueryStatistics: async (params, url, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sdcQueryStatistics,
      signal,
      params,
      true,
      false,
      url
    );
    return responseData;
  },
  sdcAggregateStatistics: async (params, url, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.sdcAggregateStatistics,
      signal,
      params,
      true,
      false,
      url
    );
    return responseData;
  },
  bsQueryTransactionStatistics: async (params, url, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.bsQueryTransactionStatistics,
      signal,
      params,
      true,
      false,
      url
    );
    return responseData;
  },
  getUserKeySellingRules: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getUserKeySellingRules,
      signal,
      params
    );
    return responseData;
  },
  downloadUserKeySellingRules: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadUserKeySellingRules,
      signal,
      params
    );
    return responseData;
  },
  deleteKeySellingRule: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteKeySellingRule,
      signal,
      params
    );
    return responseData;
  },
  addKeySellingRule: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.addKeySellingRule,
      signal,
      params
    );
    return responseData;
  },
  updateKeySellingRule: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateKeySellingRule,
      signal,
      params
    );
    return responseData;
  },
  reloadSystemConfiguration: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.reloadSystemConfiguration,
      signal,
      params
    );
    return responseData;
  },
  readSystemConfiguration: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.readSystemConfiguration,
      signal,
      params
    );
    return responseData;
  },
  updateSystemConfiguration: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.updateSystemConfiguration,
      signal,
      params
    );
    return responseData;
  },
  shutdownSystemService: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.shutdownSystemService,
      signal,
      params
    );
    return responseData;
  },
  checkValidVerifiableAuthorization: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.checkValidVerifiableAuthorization,
      signal,
      params
    );
    return responseData;
  },
  acquireVerifiableAuthorization: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.acquireVerifiableAuthorization,
      signal,
      params
    );
    return responseData;
  },
  registerDidDocument: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.registerDidDocument,
      signal,
      params
    );
    return responseData;
  },
  getSingleUserSSIEntry: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSingleUserSSIEntry,
      signal,
      params
    );
    return responseData;
  },
  downloadSingleUserSSIEntry: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.downloadSingleUserSSIEntry,
      signal,
      params
    );
    return responseData;
  },
  deleteSingleUserSSIEntry: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.deleteSingleUserSSIEntry,
      signal,
      params
    );
    return responseData;
  },
  getSSIUserEntries: async (params, signal = undefined) => {
    const responseData = await apiRequest(
      endpoints.getSSIUserEntries,
      signal,
      params
    );
    return responseData;
  },
  isObjectEmpty,
  version,
  themes,
  theme,
  capabilities,
  isCapable,
  healthCheck,
};

export default ApiService;
