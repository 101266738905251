import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ClearIcon from "@material-ui/icons/Clear";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DescriptionIcon from "@material-ui/icons/Description";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Loading from "components/Loading/Loading.js";
import AccountBox from "@material-ui/icons/AccountBox";
import CardIcon from "components/Card/CardIcon.js";
import CreditCard from "@material-ui/icons/CreditCard";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsIcon from "@material-ui/icons/Notifications";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import UserImageUpload from "components/CustomUpload/UserImageUpload.js";
import UserDocumentUpload from "components/CustomUpload/UserDocumentUpload.js";

import ApiService from "api/ApiService.js";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import isNumeric from "validator/lib/isNumeric";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbars from "components/Snackbar/Snackbar.js";
import SweetAlert from "react-bootstrap-sweetalert";
import InsertLinkIcon from "@material-ui/icons/InsertLink";

// @material-ui/core components
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";

// @material-ui/core components
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import {
  roseColor,
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles(styles);
const useStylesForm = makeStyles(formStyles);
const useStylesDialog = makeStyles(notificationStyle);
const useAlertStyles = makeStyles(sweetAlertStyles);

const theme = createTheme({
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        "&$checked": {
          // Controls checked color for the thumb
          color: primaryColor[0],
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: primaryColor[0],
        "$checked$checked + &": {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: primaryColor[0],
        },
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const OrganizationProfile = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useStylesForm();
  const dialogClasses = useStylesDialog();
  const alertClasses = useAlertStyles();

  const [loading, setLoading] = React.useState(true);

  const [parentOrganization, setParentOrganization] = React.useState("");
  const [chargeOrganization, setChargeOrganization] = React.useState([]);
  const [organizationType, setOrganizationType] = React.useState("");
  const [name, setName] = React.useState("");
  const [number, setNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [vatRate, setVatRate] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [vatNumber, setVatNumber] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [subdomains, setSubdomains] = React.useState("");
  const [landingPageUri, setLandingPageUri] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [paymailHandle, setPaymailHandle] = React.useState("");
  const [currency, setCurrency] = React.useState("");
  const [usersLimit, setUsersLimit] = React.useState("");
  const [query, setQuery] = React.useState({});
  const [organizationProfile, setOrganizationProfile] = React.useState({});
  const [roles, setRoles] = React.useState([]);
  const [checkboxes, setCheckboxes] = React.useState({});
  const [notifications, setNotifications] = React.useState({});
  const [notificationCheckboxes, setNotificationCheckboxes] = React.useState(
    {}
  );

  const [showModal, setShowModal] = React.useState(false);
  const [showModalProgress, setShowModalProgress] = React.useState(false);
  const [modalProgress, setModalProgress] = React.useState(0);
  const [errorModalMessage, setErrorModalMessage] = React.useState("");
  const [successModalMessage, setSuccessModalMessage] = React.useState("");
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [confirmModalButtonText, setConfirmModalButtonText] = React.useState(
    t("add")
  );

  const [refreshClicked, setRefreshClicked] = React.useState(false);

  const [usersList, setUsersList] = React.useState([]);
  const [modalUserId, setModalUserId] = React.useState("");
  const [modalRoleType, setModalRoleType] = React.useState(
    ApiService.organizationRoleTypes.employee
  );

  // Error handling
  const [nameError, setNameError] = React.useState("");
  const [numberError, setNumberError] = React.useState("");
  const [addressError, setAddressError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [countryError, setCountryError] = React.useState("");
  const [vatRateError, setVatRateError] = React.useState("");
  const [postalCodeError, setPostalCodeError] = React.useState("");
  const [currencyError, setCurrencyError] = React.useState("");
  const [vatNumberError, setVatNumberError] = React.useState("");
  const [contactPersonError, setContactPersonError] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState("");
  const [subdomainsError, setSubdomainsError] = React.useState("");
  const [landingPageUriError, setLandingPageUriError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [usersLimitError, setUsersLimitError] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const [showDetailsModal, setShowDetailsModal] = React.useState(false);
  const [formatDetails, setFormatDetails] = React.useState(true);
  const [detailsTitle, setDetailsTitle] = React.useState("");
  const [details, setDetails] = React.useState({});

  const [showMetadataModal, setShowMetadataModal] = React.useState(false);
  const [modalMetadataName, setModalMetadataName] = React.useState("");
  const [modalMetadataValue, setModalMetadataValue] = React.useState("");

  const [notifyLowCredits, setNotifyLowCredits] = React.useState(false);
  const [lowCreditsThreshold, setLowCreditsThreshold] = React.useState("0");

  const clearModalState = () => {
    setShowModal(false);
    setShowModalProgress(false);
    setModalProgress(0);
    setErrorModalMessage("");
    setSuccessModalMessage("");
    setConfirmModal(false);
    setConfirmModalButtonText(t("add"));
    setModalUserId("");
    setModalRoleType(ApiService.organizationRoleTypes.employee);
    setShowDetailsModal(false);
    setFormatDetails(true);
    setDetailsTitle("");
    setDetails({});
    setShowMetadataModal(false);
    setModalMetadataName("");
    setModalMetadataValue("");
  };

  const showErrorModalMessage = (messageContent, setConfirm = true) => {
    if (setConfirm) {
      setErrorModalMessage(messageContent);
      setConfirmModal(true);
      setConfirmModalButtonText(t("close"));
    } else {
      setErrorModalMessage(messageContent);
      setTimeout(() => {
        setErrorModalMessage("");
      }, ApiService.messageTimeout);
    }
  };

  const showSuccessModalMessage = (messageContent) => {
    setSuccessModalMessage(messageContent);
    setConfirmModal(true);
    setConfirmModalButtonText(t("confirm"));
  };

  const clearProfileUpdate = () => {
    setParentOrganization("");
    setChargeOrganization([]);
    setOrganizationType("");
    setName("");
    setNumber("");
    setAddress("");
    setCity("");
    setCountry("");
    setVatRate("");
    setPostalCode("");
    setCurrency("");
    setVatNumber("");
    setContactPerson("");
    setPhoneNumber("");
    setSubdomains("");
    setLandingPageUri("");
    setEmail("");
    setPaymailHandle("");
    setUsersLimit("");
  };

  const handleRefresh = () => {
    setRefreshClicked((prevCheck) => !prevCheck);
  };

  const handleReturnData = (data) => {
    if (data.users) {
      return data.users.map((entry) => {
        entry.blockedFormatted = entry.user.blocked.toString();
        entry.validatedEmailFormatted = entry.user.validatedEmail.toString();
        entry.validatedDocumentsFormatted =
          entry.user.validatedDocuments.toString();
        entry.actions = (
          <div className="actions-right">
            <Tooltip title={t("user-details")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  handleShowUserDetails(entry);
                }}
              >
                <VisibilityIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("edit")}>
              <Button
                justIcon
                round
                simple
                color="rose"
                className="edit"
                onClick={() => {
                  setModalUserId(entry.user._id);
                  setModalRoleType(entry.role);
                  setShowModal(true);
                }}
              >
                <EditIcon />
              </Button>
            </Tooltip>
            <Tooltip title={t("remove")}>
              <Button
                justIcon
                color="rose"
                simple
                className="remove"
                onClick={() => {
                  handleDeleteOrganizationUser(entry);
                }}
              >
                <ClearIcon />
              </Button>
            </Tooltip>
          </div>
        );
        return entry;
      });
    }
    return [];
  };

  const handleShowUserDetails = (organizationUser) => {
    setFormatDetails(true);
    setDetailsTitle(t("user-details-0"));
    setDetails(organizationUser.user);
    setShowDetailsModal(true);
  };

  const handleDeleteOrganizationUser = (organizationUser) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("remove-user-from-organization")}
        onConfirm={() => {
          deleteOrganizationUser(organizationUser);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{`${organizationUser.user.name} (${organizationUser.user.email})`}</b>
        </p>
      </SweetAlert>
    );
  };

  const handleAddOrganizationUser = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }
    const userId = modalUserId.trim();
    const validatedUserId = modalUserId.trim().length > 0;

    const checksPassed = validatedUserId;
    if (!checksPassed) {
      const messageContent = [];
      if (!validatedUserId) {
        messageContent.push(t("user-0"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }
    const params = {
      userId: query.userId,
      organizationId: query.parentId,
      organizationUserId: userId,
      organizationRoleType: modalRoleType,
    };

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.addOrganizationUser(params);
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage(t("user-successfully-added"));
  };

  const deleteOrganizationUser = async (organizationUser) => {
    setAlert(null);
    try {
      await ApiService.deleteOrganizationUser({
        organizationId: organizationUser.organization,
        organizationUserId: organizationUser.user._id,
      });
      operationResult(true, t("user-successfully-removed-from-organization"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  const operationResult = (result, resultMessage) => {
    const handleUpdateResult = async () => {
      handleRefresh();
      setAlert(null);
    };
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("success")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Success>{resultMessage}</Success>
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("error")}
          onConfirm={handleUpdateResult}
          onCancel={handleUpdateResult}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          <Danger>{resultMessage}</Danger>
        </SweetAlert>
      );
    }
  };

  const cancelDialog = (cancelMessage = undefined) => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title={t("cancelled")}
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
      >
        {cancelMessage}
      </SweetAlert>
    );
  };

  const handleAddMetadata = async (event) => {
    event.preventDefault();
    if (confirmModal) {
      handleRefresh();
      return clearModalState();
    }

    const name = modalMetadataName.trim();
    const validName = name.length > 0;
    const value = modalMetadataValue.trim();
    const validValue = value.length > 0;

    const checksPassed = validName && validValue;
    if (!checksPassed) {
      const messageContent = [];
      if (!validName) {
        messageContent.push(t("invalid-name"));
      }
      if (!validValue) {
        messageContent.push(t("invalid-value"));
      }
      return showErrorModalMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`,
        false
      );
    }

    // Set progress bar
    setShowModalProgress(true);
    setModalProgress(20);
    setConfirmModalButtonText(t("adding"));

    try {
      await ApiService.updateOrganizationMetadataSuperuser({
        organizationId: organizationProfile._id,
        addMetadata: [
          {
            name,
            value,
          },
        ],
      });
    } catch (e) {
      return showErrorModalMessage(e.message);
    }
    setModalProgress(100);
    return showSuccessModalMessage("Metadata successfully added");
  };
  const handleReturnMetadata = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={"Delete metadata"}>
            <Button
              justIcon
              color="rose"
              simple
              className="remove"
              onClick={() => {
                handleDeleteMetadata(elem);
              }}
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const handleDeleteMetadata = (elem) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={"Delete metadata"}
        onConfirm={() => {
          deleteMetadata(elem);
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      >
        <p>
          <b>{elem.name}</b>
        </p>
      </SweetAlert>
    );
  };
  const deleteMetadata = async (metadata) => {
    setAlert(null);
    try {
      await ApiService.updateOrganizationMetadataSuperuser({
        organizationId: organizationProfile._id,
        removeMetadata: [metadata.name],
      });
      operationResult(true, "Metadata successfully deleted");
    } catch (e) {
      operationResult(false, e.message);
    }
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    const params = {
      userId: queryParams.get("userId"),
      parentId: queryParams.get("parentId"),
      parentType: queryParams.get("parentType"),
    };
    let isMounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const setOrganizationProfileAndActions = async (
      organizationData,
      availableNotifications,
      superuser = false
    ) => {
      setOrganizationProfile(organizationData);
      if (superuser) {
        const allRoles = await ApiService.getRoles({
          sortBy: "name:asc",
        });
        const organizationRoles = {};
        organizationData.assignedRoles.forEach((role) => {
          organizationRoles[role.name] = role.type;
        });
        const checkboxes_ = {};
        // eslint-disable-next-line no-unused-vars
        for (const role_ of allRoles) {
          if (organizationRoles.hasOwnProperty(role_.name)) {
            role_.resolvingType = ApiService.resolvingTypes.native;
            checkboxes_[role_._id] = {
              name: role_.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          } else {
            role_.resolvingType = ApiService.resolvingTypes.assignable;
            checkboxes_[role_._id] = {
              name: role_.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          }
        }
        setCheckboxes(checkboxes_);
        setRoles(allRoles);
      }
      const availableServices = new Set([
        ApiService.serviceTypes.id,
        ...organizationData.services.map((service) => service.type),
      ]);
      const organizationDisabledNotifications = {};
      if (organizationData.disabledNotifications) {
        organizationData.disabledNotifications.forEach(
          (disabledNotification) => {
            organizationDisabledNotifications[disabledNotification] = true;
          }
        );
      }
      const notificationCheckboxes_ = {};
      const updateableNotifications = [];
      // eslint-disable-next-line no-unused-vars
      for (const notification of availableNotifications) {
        if (availableServices.has(notification.serviceType)) {
          if (
            organizationDisabledNotifications.hasOwnProperty(notification.name)
          ) {
            notification.type = ApiService.notificationTypes.disabled;
            notificationCheckboxes_[notification.name] = {
              name: notification.name,
              disabled: false,
              initChecked: false,
              checked: false,
            };
          } else {
            notification.type = ApiService.notificationTypes.enabled;
            notificationCheckboxes_[notification.name] = {
              name: notification.name,
              disabled: false,
              initChecked: true,
              checked: true,
            };
          }
          updateableNotifications.push(notification);
        }
      }
      setNotificationCheckboxes(notificationCheckboxes_);
      setNotifications(updateableNotifications);
    };

    const apiOperations = async () => {
      try {
        const returnData = await ApiService.loginRequired(signal, false);
        let queryValue;
        const params_ = {};
        if (!returnData.superuser) {
          params_.serviceType = ApiService.serviceTypes.sdc;
        }
        const users = await ApiService.getAllUsers(params_, signal);
        setUsersList(users);
        const availableNotifications =
          await ApiService.getAvailableNotifications(signal);
        const userProfileData = await ApiService.readUser(params, signal);
        queryValue = {
          superuser: returnData.superuser,
          userId:
            typeof userProfileData.superuser !== "undefined"
              ? userProfileData._id
              : returnData._id,
        };
        await setOrganizationProfileAndActions(
          userProfileData,
          availableNotifications,
          returnData.superuser
        );
        if (params.parentId) {
          queryValue.parentId = params.parentId;
        }
        if (params.parentType) {
          queryValue.parentType = params.parentType;
        }
        setQuery(queryValue);
        setLoading(false);
      } catch (e) {
        console.error(e);
        props.history.push("/admin/dashboard");
      }
    };
    isMounted && apiOperations();
    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [props.location.search, props.history, refreshClicked]);

  React.useEffect(() => {
    if (!ApiService.isObjectEmpty(organizationProfile)) {
      setParentOrganization(
        organizationProfile.parentOrg ? organizationProfile.parentOrg : ""
      );
      setChargeOrganization(organizationProfile.chargeOrganization ? [1] : []);
      setOrganizationType(organizationProfile.type);
      setName(organizationProfile.name);
      setNumber(organizationProfile.number ? organizationProfile.number : "");
      setAddress(
        organizationProfile.address ? organizationProfile.address : ""
      );
      setCity(organizationProfile.city ? organizationProfile.city : "");
      setCountry(
        organizationProfile.country ? organizationProfile.country : ""
      );
      setVatRate(
        organizationProfile.vatRate ? organizationProfile.vatRate : ""
      );
      setPostalCode(
        organizationProfile.postalCode ? organizationProfile.postalCode : ""
      );
      setCurrency(
        organizationProfile.currency ? organizationProfile.currency : ""
      );
      setVatNumber(
        organizationProfile.vatNumber ? organizationProfile.vatNumber : ""
      );
      setContactPerson(
        organizationProfile.contactPerson
          ? organizationProfile.contactPerson
          : ""
      );
      setPhoneNumber(
        organizationProfile.phoneNumber ? organizationProfile.phoneNumber : ""
      );
      setSubdomains(
        organizationProfile.subdomains ? organizationProfile.subdomains : ""
      );
      setLandingPageUri(
        organizationProfile.landingPageUri
          ? organizationProfile.landingPageUri
          : ""
      );
      setEmail(organizationProfile.email);
      setPaymailHandle(
        organizationProfile.paymailHandles.length > 0
          ? organizationProfile.paymailHandles[0].handle
          : ""
      );
      setUsersLimit(organizationProfile.usersLimit.toString());
      setNotifyLowCredits(!!organizationProfile.notifyLowCredits);
      setLowCreditsThreshold(
        organizationProfile.lowCreditsThreshold
          ? organizationProfile.lowCreditsThreshold
          : "0"
      );
    }
  }, [organizationProfile]);

  const showErrorMessage = (messageContent) => {
    setSuccessMessage("");
    setErrorMessage(messageContent);
    setTimeout(() => {
      setNameError("");
      setNumberError("");
      setAddressError("");
      setCityError("");
      setCountryError("");
      setVatRateError("");
      setPostalCodeError("");
      setCurrencyError("");
      setVatNumberError("");
      setContactPersonError("");
      setPhoneNumberError("");
      setSubdomainsError("");
      setLandingPageUriError("");
      setEmailError("");
      setUsersLimitError("");
      setErrorMessage("");
    }, ApiService.messageTimeout);
  };
  const showSuccessMessage = (messageContent) => {
    setErrorMessage("");
    setSuccessMessage(messageContent);
    setTimeout(() => {
      setSuccessMessage("");
    }, ApiService.messageTimeout);
  };
  const handleUpdateProfileSubmit = async (event) => {
    event.preventDefault();
    const requestData = {
      organizationId: organizationProfile._id,
      chargeOrganization: chargeOrganization.length > 0,
    };
    const messageContent = [];
    let checksPassed = true;
    if (
      parentOrganization.length > 0 &&
      parentOrganization !== organizationProfile.parentOrg
    ) {
      requestData.parentOrg = parentOrganization;
    }
    if (
      organizationType.length > 0 &&
      organizationType !== organizationProfile.type
    ) {
      requestData.type = organizationType;
    }
    if (name.length > 0 && name !== organizationProfile.name) {
      requestData.name = name;
    }
    if (number.length > 0 && number !== organizationProfile.number) {
      requestData.number = number;
    }
    if (address.length > 0 && address !== organizationProfile.address) {
      requestData.address = address;
    }
    if (city.length > 0 && city !== organizationProfile.city) {
      requestData.city = city;
    }
    if (country.length > 0 && country !== organizationProfile.country) {
      requestData.country = country;
    }
    if (
      postalCode.length > 0 &&
      postalCode !== organizationProfile.postalCode
    ) {
      requestData.postalCode = postalCode;
    }
    if (currency.length > 0 && currency !== organizationProfile.currency) {
      requestData.currency = currency;
    }
    if (vatNumber.length > 0 && vatNumber !== organizationProfile.vatNumber) {
      requestData.vatNumber = vatNumber;
    }
    if (
      contactPerson.length > 0 &&
      contactPerson !== organizationProfile.contactPerson
    ) {
      requestData.contactPerson = contactPerson;
    }
    if (
      phoneNumber.length > 0 &&
      phoneNumber !== organizationProfile.phoneNumber
    ) {
      requestData.phoneNumber = phoneNumber;
    }
    if (
      subdomains.length > 0 &&
      subdomains !== organizationProfile.subdomains
    ) {
      requestData.subdomains = subdomains;
    }
    if (
      landingPageUri.length > 0 &&
      landingPageUri !== organizationProfile.landingPageUri
    ) {
      requestData.landingPageUri = landingPageUri;
    }
    if (email.length > 0 && email !== organizationProfile.email) {
      const validEmail = isEmail(email);
      if (validEmail) {
        requestData.email = email;
      } else {
        setEmailError(t("invalid-email-address"));
        messageContent.push(t("email-address"));
        checksPassed = false;
      }
    }
    const currentPaymailHandle =
      organizationProfile.paymailHandles.length > 0
        ? organizationProfile.paymailHandles[0].handle
        : "";
    if (paymailHandle.length > 0 && paymailHandle !== currentPaymailHandle) {
      requestData.paymailHandle = paymailHandle === " " ? "" : paymailHandle;
    }
    if (
      usersLimit.length > 0 &&
      usersLimit !== organizationProfile.usersLimit.toString()
    ) {
      let validUsersLimit = isNumeric(usersLimit);
      if (validUsersLimit) {
        const usersLimitParsed = parseInt(usersLimit, 10);
        if (usersLimitParsed >= 0) {
          requestData.usersLimit = usersLimitParsed;
        } else {
          validUsersLimit = false;
        }
      }
      if (!validUsersLimit) {
        setUsersLimitError(t("invalid-users-limit"));
        messageContent.push(t("users-limit"));
        checksPassed = false;
      }
    }
    if (vatRate.length > 0 && vatRate !== organizationProfile.vatRate) {
      let validVatRate = isNumeric(vatRate);
      if (validVatRate) {
        const vatRateParsed = parseInt(vatRate, 10);
        if (vatRateParsed >= 0) {
          requestData.vatRate = vatRate;
        } else {
          validVatRate = false;
        }
      }
      if (!validVatRate) {
        setVatRateError(t("invalid-vat-rate"));
        messageContent.push(t("vat-rate"));
        checksPassed = false;
      }
    }
    if (!checksPassed) {
      showErrorMessage(
        `${t("invalid-information-provided")}: ${messageContent.join(", ")}.`
      );
    }
    if (!checksPassed || Object.keys(requestData).length === 1) {
      return;
    }

    try {
      if (query.superuser) {
        await ApiService.updateOrganizationSuperuser(requestData);
      } else {
        const userRequestData = {
          userId: query.userId,
          organizationId: requestData.organizationId,
          address: requestData.address,
          city: requestData.city,
          postalCode: requestData.postalCode,
          country: requestData.country,
          contactPerson: requestData.contactPerson,
          phoneNumber: requestData.phoneNumber,
          landingPageUri: requestData.landingPageUri,
        };
        await ApiService.updateOrganizationAttributes(userRequestData);
      }
      showSuccessMessage(t("successfully-updated-profile-fields"));
      clearProfileUpdate();
    } catch (e) {
      if (e.message.includes("email")) {
        showErrorMessage(t("organization-with-the-same-email-already-exists"));
      } else if (e.message.includes("name")) {
        showErrorMessage(t("organization-with-the-same-name-already-exists"));
      } else {
        showErrorMessage(e.message);
      }
    }
    handleRefresh();
  };
  const handleSendValidationEmail = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.sendUserValidateEmailLink({
        parentId: organizationProfile._id,
        parentType: ApiService.parentTypes.organization,
      });
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const handleRequestDocumentsValidation = async (event) => {
    event.preventDefault();
    try {
      const responseData = await ApiService.requestUserDocumentsValidation({
        parentId: organizationProfile._id,
        parentType: ApiService.parentTypes.organization,
        requestDocumentsValidation: true,
      });
      handleRefresh();
      showSuccessMessage(responseData.message);
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const deleteProfileRedirect = () => {
    setAlert(null);
    if (query.superuser) {
      props.history.push("/admin/admin-organizations");
    } else {
      props.history.push("/");
    }
  };
  const deleteConfirmAlertNext = (result, resultMessage) => {
    if (result) {
      setAlert(
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={t("confirmed")}
          onConfirm={deleteProfileRedirect}
          onCancel={deleteProfileRedirect}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    } else {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={t("cancelled")}
          onConfirm={() => setAlert(null)}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}
        >
          {resultMessage}
        </SweetAlert>
      );
    }
  };
  const showDeleteProfile = () => {
    setAlert(
      <SweetAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title={t("delete-profile")}
        onConfirm={async (e) => {
          if (e === organizationProfile.name) {
            try {
              await ApiService.deleteOrganizationSuperuser({
                organizationId: query.parentId,
              });
              deleteConfirmAlertNext(
                true,
                t("profile-deletion-process-started")
              );
            } catch (e) {
              deleteConfirmAlertNext(
                false,
                t("error-deleting-profile-please-contact-the-support")
              );
            }
          } else {
            deleteConfirmAlertNext(false, t("names-do-not-match"));
          }
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
      >
        <p>
          {t("deleting-profile-will")}{" "}
          <b>{t("remove-all-of-organization-data")}</b>.
          {t("organization-profile-removal-message")}
        </p>
        <p>
          {t("to-delete-profile-confirm")} <b>{t("name")}</b> {t("below")}.
        </p>
      </SweetAlert>
    );
  };
  const handleRolesData = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("assign-unassign")}>
            <Checkbox
              color="default"
              key={elem._id}
              checked={checkboxes[elem._id] && checkboxes[elem._id].checked}
              className={classes.positionAbsolute}
              tabIndex={-1}
              onClick={() => {
                const checkboxesUpdate = { ...checkboxes };
                checkboxesUpdate[elem._id] = {
                  ...checkboxes[elem._id],
                  checked: !checkboxes[elem._id].checked,
                };
                setCheckboxes(checkboxesUpdate);
              }}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const handleNotifications = (data) => {
    return data.map((elem) => {
      elem.actions = (
        <div className="actions-right">
          <Tooltip title={t("enable-disable")}>
            <Checkbox
              color="default"
              key={elem.name}
              checked={
                notificationCheckboxes[elem.name] &&
                notificationCheckboxes[elem.name].checked
              }
              className={classes.positionAbsolute}
              tabIndex={-1}
              onClick={() => {
                const notificationCheckboxesUpdate = {
                  ...notificationCheckboxes,
                };
                notificationCheckboxesUpdate[elem.name] = {
                  ...notificationCheckboxes[elem.name],
                  checked: !notificationCheckboxes[elem.name].checked,
                };
                setNotificationCheckboxes(notificationCheckboxesUpdate);
              }}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          </Tooltip>
        </div>
      );
      return elem;
    });
  };
  const filterUpdates = (inputCheckboxes) => {
    const addObjects = [];
    const deleteObjects = [];

    const objectIds = Object.keys(inputCheckboxes);
    // eslint-disable-next-line no-unused-vars
    for (const objectId of objectIds) {
      const entry = inputCheckboxes[objectId];
      if (!entry.disabled) {
        if (entry.initChecked && !entry.checked) {
          deleteObjects.push(entry.name);
        } else if (!entry.initChecked && entry.checked) {
          addObjects.push(entry.name);
        }
      }
    }

    return {
      addObjects,
      deleteObjects,
    };
  };
  const handleUpdateAssignedRoles = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(checkboxes);
      if (addObjects.length > 0) {
        await ApiService.addUserRoles({
          parentId: organizationProfile._id,
          parentType: ApiService.parentTypes.organization,
          roles: addObjects,
        });
      }
      if (deleteObjects.length > 0) {
        await ApiService.deleteUserRoles({
          parentId: organizationProfile._id,
          parentType: ApiService.parentTypes.organization,
          roles: deleteObjects,
        });
      }
      operationResult(true, t("successfully-updated-roles"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };
  const handleUpdateRoles = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-roles")}
        onConfirm={() => {
          handleUpdateAssignedRoles();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };
  const handleUpdateDisabledNotifications = async () => {
    setAlert(null);
    try {
      const { addObjects, deleteObjects } = filterUpdates(
        notificationCheckboxes
      );
      if (addObjects.length > 0 || deleteObjects.length > 0) {
        await ApiService.updateOrganizationNotifications({
          organizationId: organizationProfile._id,
          addDisableNotifications: deleteObjects,
          removeDisableNotifications: addObjects,
        });
      }
      operationResult(true, t("successfully-updated-notifications"));
    } catch (e) {
      operationResult(false, e.message);
    }
  };
  const handleUpdateNotifications = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("update-notifications")}
        onConfirm={() => {
          handleUpdateDisabledNotifications();
        }}
        onCancel={() => {
          cancelDialog();
        }}
        confirmBtnCssClass={alertClasses.button + " " + alertClasses.primary}
        cancelBtnCssClass={
          alertClasses.button +
          " " +
          alertClasses.simple +
          " " +
          alertClasses.github
        }
        confirmBtnText={t("confirm")}
        cancelBtnText={t("cancel")}
        showCancel
      />
    );
  };
  const handleLowCreditsNotificationAction = async (
    toggleActive,
    userProfileData
  ) => {
    if (userProfileData.notifyLowCredits) {
      if (toggleActive) {
        setNotifyLowCredits(true);
      } else {
        try {
          if (query.superuser) {
            await ApiService.updateOrganizationSuperuser({
              organizationId: organizationProfile._id,
              notifyLowCredits: false,
            });
          } else {
            await ApiService.updateOrganizationAttributes({
              userId: query.userId,
              organizationId: organizationProfile._id,
              notifyLowCredits: false,
            });
          }
          setNotifyLowCredits(false);
          handleRefresh();
        } catch (e) {
          return showErrorMessage(e.message);
        }
      }
    } else {
      if (toggleActive) {
        try {
          if (query.superuser) {
            await ApiService.updateOrganizationSuperuser({
              organizationId: organizationProfile._id,
              notifyLowCredits: true,
              lowCreditsThreshold,
            });
          } else {
            await ApiService.updateOrganizationAttributes({
              userId: query.userId,
              organizationId: organizationProfile._id,
              notifyLowCredits: true,
              lowCreditsThreshold,
            });
          }
          setNotifyLowCredits(true);
          handleRefresh();
        } catch (e) {
          return showErrorMessage(e.message);
        }
      } else {
        setNotifyLowCredits(false);
      }
    }
  };
  const handleLowCreditsNotificationSubmit = async (event) => {
    event.preventDefault();
    try {
      if (query.superuser) {
        await ApiService.updateOrganizationSuperuser({
          organizationId: organizationProfile._id,
          notifyLowCredits,
          lowCreditsThreshold,
        });
      } else {
        await ApiService.updateOrganizationAttributes({
          userId: query.userId,
          organizationId: organizationProfile._id,
          notifyLowCredits,
          lowCreditsThreshold,
        });
      }
      showSuccessMessage(t("threshold-successfully-updated"));
    } catch (e) {
      showErrorMessage(e.message);
    }
  };
  const redirectToView = (viewLink) => {
    const queryParameters = new URLSearchParams({
      userId: query.userId,
      parentId: query.parentId,
      parentType: query.parentType,
    }).toString();
    props.history.push(`${viewLink}?${queryParameters}`);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showMetadataModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{"Add metadata"}</h4>
        </DialogTitle>
        <form onSubmit={handleAddMetadata}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("name")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="metadata-name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalMetadataName,
                    onChange: (e) => {
                      setModalMetadataName(e.target.value);
                    },
                  }}
                  helpText={t("name")}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={formClasses.labelHorizontal}
                  style={{ float: "left" }}
                >
                  {t("value")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <CustomInput
                  id="metadata-value"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "text",
                    value: modalMetadataValue,
                    onChange: (e) => {
                      setModalMetadataValue(e.target.value);
                    },
                  }}
                  helpText={t("value")}
                />
              </GridItem>
            </GridContainer>

            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showDetailsModal}
        transition={Transition}
        keepMounted
        scroll="body"
        fullWidth
        maxWidth="sm"
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowDetailsModal(false);
            setFormatDetails(true);
            setDetailsTitle("");
            setDetails({});
          }
        }}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{detailsTitle}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            {formatDetails &&
              Object.entries(details).map(([fieldName, fieldValue]) => {
                let data;
                if (typeof fieldValue === "object") {
                  data = (
                    <div style={{ whiteSpace: "pre-wrap" }}>
                      {JSON.stringify(fieldValue, undefined, 2)}
                    </div>
                  );
                } else {
                  data = (
                    <React.Fragment>{fieldValue.toString()}</React.Fragment>
                  );
                }
                return (
                  <React.Fragment key={uuidv4()}>
                    <GridItem
                      xs={12}
                      sm={7}
                      md={5}
                      style={{ fontWeight: "400" }}
                    >
                      {fieldName}
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={5}
                      md={7}
                      className={classes.wordBreak}
                    >
                      {data}
                    </GridItem>
                  </React.Fragment>
                );
              })}
            {!formatDetails && (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(details, undefined, 2)}
              </div>
            )}
          </GridContainer>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button
            onClick={() => {
              setShowDetailsModal(false);
              setFormatDetails(true);
              setDetailsTitle("");
              setDetails({});
            }}
          >
            {t("close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            clearModalState();
          }
        }}
        fullWidth
        maxWidth="sm"
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
        disableEnforceFocus
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={dialogClasses.modalHeader}
        >
          <h4 className={dialogClasses.modalTitle}>{t("add-user")}</h4>
        </DialogTitle>
        <form onSubmit={handleAddOrganizationUser}>
          <DialogContent
            id="notice-modal-slide-description"
            className={dialogClasses.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("user")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="user"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalUserId}
                    onChange={(e) => {
                      setModalUserId(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "user",
                      id: "user",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("user")}
                    </MenuItem>
                    {usersList.map((user) => {
                      return (
                        <MenuItem
                          key={user._id}
                          classes={{
                            root: formClasses.selectMenuItem,
                            selected:
                              formClasses.selectMenuItemSelectedMultiple,
                          }}
                          value={user._id}
                        >
                          {`${user.name} (${user.email})`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <FormLabel
                  className={
                    formClasses.labelHorizontal +
                    " " +
                    formClasses.labelHorizontalRadioCheckbox
                  }
                  style={{ float: "left", paddingTop: "40px" }}
                >
                  {t("role-type")}
                </FormLabel>
              </GridItem>
              <GridItem xs={12} sm={9}>
                <FormControl
                  fullWidth
                  className={formClasses.selectFormControl}
                >
                  <InputLabel
                    htmlFor="role-type"
                    className={formClasses.selectLabel}
                  ></InputLabel>
                  <Select
                    value={modalRoleType}
                    onChange={(e) => {
                      setModalRoleType(e.target.value);
                    }}
                    MenuProps={{ className: formClasses.selectMenu }}
                    classes={{ select: formClasses.select }}
                    inputProps={{
                      name: "roleType",
                      id: "roleType",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: formClasses.selectMenuItem,
                      }}
                    >
                      {t("role-type")}
                    </MenuItem>
                    {Object.values(ApiService.organizationRoleTypes).map(
                      (roleType) => {
                        return (
                          <MenuItem
                            key={roleType}
                            classes={{
                              root: formClasses.selectMenuItem,
                              selected:
                                formClasses.selectMenuItemSelectedMultiple,
                            }}
                            value={roleType}
                          >
                            {roleType}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            {showModalProgress && (
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <FormLabel
                    className={
                      formClasses.labelHorizontal +
                      " " +
                      formClasses.labelHorizontalRadioCheckbox
                    }
                    style={{ float: "left" }}
                  >
                    {t("progress")}
                  </FormLabel>
                </GridItem>
                <GridItem xs={12} sm={9}>
                  <div className={formClasses.checkboxAndRadio}>
                    <FormControl fullWidth>
                      <br />
                      <CustomLinearProgress
                        variant="determinate"
                        color="primary"
                        value={modalProgress}
                      />
                      <Muted>{modalProgress}%</Muted>
                    </FormControl>
                  </div>
                </GridItem>
              </GridContainer>
            )}
            <br />
            {errorModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Danger>{errorModalMessage}</Danger>
                </GridItem>
              </GridContainer>
            )}
            {successModalMessage && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Success>{successModalMessage}</Success>
                </GridItem>
              </GridContainer>
            )}
            <br />
          </DialogContent>
          <DialogActions
            className={
              dialogClasses.modalFooter + " " + dialogClasses.modalFooterCenter
            }
          >
            <Button
              onClick={() => {
                clearModalState();
              }}
              disabled={confirmModal}
              color="rose"
              simple
            >
              {t("cancel")}
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={showModalProgress && !confirmModal}
            >
              {confirmModalButtonText}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <InsertLinkIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("view-links")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="center">
                {query.superuser && (
                  <Button
                    simple
                    color="rose"
                    round
                    onClick={() => redirectToView("/admin/admin-services")}
                  >
                    {t("services")}
                  </Button>
                )}
                <Button
                  simple
                  color="rose"
                  round
                  onClick={() => redirectToView("/admin/user-documents")}
                >
                  {t("documents")}
                </Button>
                <Button
                  simple
                  color="rose"
                  round
                  onClick={() => redirectToView("/admin/user-subscriptions")}
                >
                  {t("subscriptions")}
                </Button>
                <Button
                  simple
                  color="rose"
                  round
                  onClick={() => redirectToView("/admin/user-billings")}
                >
                  {t("billings")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  onClick={() => redirectToView("/admin/user-lookups")}
                >
                  {t("lookups")}
                </Button>
                <Button
                  color="rose"
                  round
                  simple
                  onClick={() => redirectToView("/admin/user-audit-logs")}
                >
                  {t("audit-logs")}
                </Button>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={8}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <AccountBox style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("users")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => handleRefresh()}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
                <Button
                  color="primary"
                  style={{ marginTop: "20px" }}
                  round
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  {t("add")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={
                  !ApiService.isObjectEmpty(organizationProfile)
                    ? handleReturnData(organizationProfile)
                    : []
                }
                filterable
                columns={[
                  {
                    Header: t("role"),
                    accessor: "role",
                  },
                  {
                    Header: t("name"),
                    accessor: "user.name",
                  },
                  {
                    Header: t("email-address-0"),
                    accessor: "user.email",
                  },
                  {
                    Header: t("actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={4}>
          <Card profile>
            <CardBody profile>
              <UserImageUpload
                userId={query.userId}
                parentId={query.parentId}
                parentType={query.parentType}
                update={true}
                encrypt={true}
                resize={true}
                extractImageText={false}
                resizeWidth={400}
                resizeHeight={400}
                documentType={ApiService.documentTypes.avatar}
                avatar={true}
              />
              <h6 className={classes.cardCategory}>
                {organizationProfile.name}
              </h6>
              <h4 className={classes.cardTitle}>{organizationProfile.email}</h4>

              {organizationProfile.number &&
                organizationProfile.number.length > 0 && (
                  <GridContainer
                    className={`${classes.description} ${classes.cardMargin}`}
                  >
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("number")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {organizationProfile.number}
                    </GridItem>
                  </GridContainer>
                )}
              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("type")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.capitalize(organizationProfile.type)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("paymail")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {organizationProfile.paymail}
                </GridItem>
              </GridContainer>

              {organizationProfile.paymailHandles.length > 0 && (
                <GridContainer
                  className={`${classes.description} ${classes.cardMargin}`}
                >
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("paymail-handle")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {`${organizationProfile.paymailHandles[0].handle} (${organizationProfile.paymailHandles[0].type})`}
                  </GridItem>
                </GridContainer>
              )}

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("created-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(organizationProfile.createdAt)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("updated-at")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {ApiService.formatDateTime(organizationProfile.updatedAt)}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("credits")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {organizationProfile.credits}
                </GridItem>
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("charge-organization-0")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {typeof organizationProfile.chargeOrganization !== "undefined"
                    ? organizationProfile.chargeOrganization.toString()
                    : "false"}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMargin}`}
              >
                {typeof organizationProfile.numberOfUsers === "number" && (
                  <React.Fragment>
                    <GridItem xs={12} sm={4} className={classes.alignLeft}>
                      {t("number-of-users")}
                    </GridItem>
                    <GridItem xs={12} sm={8} className={classes.alignRight}>
                      {organizationProfile.numberOfUsers.toString()}
                    </GridItem>
                  </React.Fragment>
                )}
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("users-limit-1")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {organizationProfile.usersLimit === 0
                    ? t("unlimited")
                    : organizationProfile.usersLimit.toString()}
                </GridItem>
              </GridContainer>

              <GridContainer
                className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
              >
                <GridItem xs={12} sm={4} className={classes.alignLeft}>
                  {t("validated-email-0")}
                </GridItem>
                <GridItem xs={12} sm={8} className={classes.alignRight}>
                  {organizationProfile.validatedEmail ? t("true") : t("false")}
                </GridItem>
              </GridContainer>

              {organizationProfile.validatedDocuments && (
                <GridContainer
                  className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
                >
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("validated-documents-0")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {organizationProfile.validatedDocuments
                      ? t("true")
                      : t("false")}
                  </GridItem>
                </GridContainer>
              )}
              {!organizationProfile.validatedDocuments && (
                <GridContainer
                  className={`${classes.description} ${classes.cardMarginBottom} ${classes.marginIn}`}
                >
                  <GridItem xs={12} sm={4} className={classes.alignLeft}>
                    {t("requested-validation")}
                  </GridItem>
                  <GridItem xs={12} sm={8} className={classes.alignRight}>
                    {organizationProfile.requestDocumentsValidation
                      ? "true"
                      : "false"}
                  </GridItem>
                </GridContainer>
              )}

              {!organizationProfile.validatedEmail && (
                <Button
                  color="rose"
                  round
                  simple
                  onClick={handleSendValidationEmail}
                >
                  {t("resend-validation-link")}
                </Button>
              )}
              {organizationProfile.validatedEmail &&
                !organizationProfile.validatedDocuments &&
                !organizationProfile.requestDocumentsValidation && (
                  <Button
                    color="rose"
                    round
                    simple
                    onClick={handleRequestDocumentsValidation}
                  >
                    {t("request-documents-validation")}
                  </Button>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color="info"
            alignCenter
            tabs={[
              query.superuser
                ? {
                    tabButton: t("assigned-roles"),
                    tabIcon: GroupWorkIcon,
                    tabContent: (
                      <Card>
                        <CardHeader
                          color="turq"
                          icon
                          style={{ display: "inline-flex" }}
                        >
                          <CardIcon color="turq">
                            <GroupWorkIcon style={{ color: roseColor[0] }} />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {t("assigned-roles")}
                          </h4>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              simple
                              color="rose"
                              className="remove"
                              onClick={() => handleRefresh()}
                              style={{ marginTop: "28px" }}
                            >
                              {t("refresh")}
                            </Button>
                            <Button
                              color="primary"
                              style={{ marginTop: "20px" }}
                              round
                              onClick={() => {
                                handleUpdateRoles();
                              }}
                            >
                              {t("update")}
                            </Button>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={handleRolesData(roles)}
                            filterable
                            columns={[
                              {
                                Header: t("name"),
                                accessor: "name",
                              },
                              {
                                Header: t("type"),
                                accessor: "type",
                              },
                              {
                                Header: t("status"),
                                accessor: "resolvingType",
                              },
                              {
                                Header: t("actions"),
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    ),
                  }
                : {
                    tabButton: t("assigned-roles"),
                    tabIcon: GroupWorkIcon,
                    tabContent: (
                      <Card>
                        <CardHeader
                          color="turq"
                          icon
                          style={{ display: "inline-flex" }}
                        >
                          <CardIcon color="turq">
                            <GroupWorkIcon style={{ color: roseColor[0] }} />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                            {t("assigned-roles")}
                          </h4>
                          <div style={{ marginLeft: "auto" }}>
                            <Button
                              simple
                              color="rose"
                              className="remove"
                              onClick={() => handleRefresh()}
                              style={{ marginTop: "28px" }}
                            >
                              {t("refresh")}
                            </Button>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={organizationProfile.assignedRoles}
                            filterable
                            columns={[
                              {
                                Header: t("name"),
                                accessor: "name",
                              },
                              {
                                Header: t("type"),
                                accessor: "type",
                              },
                            ]}
                            defaultPageSize={5}
                            showPaginationTop={false}
                            showPaginationBottom
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    ),
                  },
              {
                tabButton: t("capabilities"),
                tabIcon: VerifiedUserIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <VerifiedUserIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("capabilities")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={organizationProfile.capabilities}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("type"),
                            accessor: "type",
                          },
                        ]}
                        defaultPageSize={5}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
              {
                tabButton: t("notifications"),
                tabIcon: NotificationsIcon,
                tabContent: (
                  <Card>
                    <CardHeader
                      color="turq"
                      icon
                      style={{ display: "inline-flex" }}
                    >
                      <CardIcon color="turq">
                        <GroupWorkIcon style={{ color: roseColor[0] }} />
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>
                        {t("notifications")}
                      </h4>
                      <div style={{ marginLeft: "auto" }}>
                        <Button
                          simple
                          color="rose"
                          className="remove"
                          onClick={() => handleRefresh()}
                          style={{ marginTop: "28px" }}
                        >
                          {t("refresh")}
                        </Button>
                        <Button
                          color="primary"
                          style={{ marginTop: "20px" }}
                          round
                          onClick={() => {
                            handleUpdateNotifications();
                          }}
                        >
                          {t("update")}
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <ReactTable
                        data={handleNotifications(notifications)}
                        filterable
                        columns={[
                          {
                            Header: t("name"),
                            accessor: "name",
                          },
                          {
                            Header: t("service"),
                            accessor: "serviceType",
                          },
                          {
                            Header: t("status"),
                            accessor: "type",
                          },
                          {
                            Header: t("description"),
                            accessor: "description",
                          },
                          {
                            Header: t("actions"),
                            accessor: "actions",
                            sortable: false,
                            filterable: false,
                          },
                        ]}
                        defaultPageSize={5}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                      />
                    </CardBody>
                  </Card>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <DescriptionIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("logo")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="center">
                <UserImageUpload
                  userId={query.userId}
                  parentId={query.parentId}
                  parentType={query.parentType}
                  update={true}
                  encrypt={true}
                  resize={true}
                  extractImageText={false}
                  resizeWidth={400}
                  resizeHeight={400}
                  documentType={ApiService.documentTypes.logo}
                  avatar={false}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <DescriptionIcon style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("company-documents")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer justifyContent="center">
                <UserDocumentUpload
                  userId={query.userId}
                  parentId={query.parentId}
                  parentType={query.parentType}
                  update={
                    query.superuser || !organizationProfile.validatedDocuments
                  }
                  encrypt={true}
                  documentType={ApiService.documentTypes.companyDocuments}
                />
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="turq" icon>
              <CardIcon color="turq">
                <CreditCard style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {t("low-credits-email-notifications")}
              </h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleLowCreditsNotificationSubmit}>
                <GridContainer
                  className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                >
                  <GridItem xs={12}>
                    <ThemeProvider theme={theme}>
                      <div className={classes.block}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={notifyLowCredits}
                              onChange={(event) => {
                                handleLowCreditsNotificationAction(
                                  event.target.checked,
                                  organizationProfile
                                );
                              }}
                              value="notifyLowCredits"
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                                thumb: classes.switchIcon,
                                track: classes.switchBar,
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                          }}
                          label={
                            notifyLowCredits ? t("enabled") : t("disabled")
                          }
                        />
                        {notifyLowCredits && (
                          <GridContainer
                            className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                          >
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              style={{ textAlign: "right" }}
                            >
                              <CustomInput
                                labelText={t("low-credits-threshold")}
                                id="low-credits-threshold"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                inputProps={{
                                  onChange: (e) => {
                                    setLowCreditsThreshold(e.target.value);
                                  },
                                  value: lowCreditsThreshold,
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        )}
                      </div>
                    </ThemeProvider>
                  </GridItem>
                </GridContainer>
                {notifyLowCredits && (
                  <Button
                    color="primary"
                    type="submit"
                    className={classes.updateProfileButton}
                  >
                    {t("update")}
                  </Button>
                )}
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
              <CardIcon color="turq">
                <PermIdentity style={{ color: roseColor[0] }} />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("edit-profile")}</h4>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  simple
                  color="rose"
                  className="remove"
                  onClick={() => {
                    handleRefresh();
                  }}
                  style={{ marginTop: "28px" }}
                >
                  {t("refresh")}
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleUpdateProfileSubmit}>
                {query.superuser && (
                  <React.Fragment>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t("parent-organization-0")}
                          id="parent-organization"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setParentOrganization(e.target.value);
                            },
                            value: parentOrganization,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText={t("currency")}
                          id="currency"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setCurrency(e.target.value);
                            },
                            value: currency,
                          }}
                          error={currencyError.length > 0}
                          helperText={currencyError}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ float: "left" }}
                      >
                        <div className={formClasses.checkboxAndRadio}>
                          <FormControlLabel
                            style={{ float: "left" }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => {
                                  const currentIndex =
                                    chargeOrganization.indexOf(1);
                                  const newChecked = [...chargeOrganization];
                                  if (currentIndex === -1) {
                                    newChecked.push(1);
                                  } else {
                                    newChecked.splice(currentIndex, 1);
                                  }
                                  setChargeOrganization(newChecked);
                                }}
                                checkedIcon={
                                  <Check className={formClasses.checkedIcon} />
                                }
                                icon={
                                  <Check
                                    className={formClasses.uncheckedIcon}
                                  />
                                }
                                classes={{
                                  checked: formClasses.checked,
                                  root: formClasses.checkRoot,
                                }}
                                checked={chargeOrganization.length > 0}
                              />
                            }
                            classes={{
                              label: formClasses.label,
                              root: formClasses.labelRoot,
                            }}
                            label={t("charge-organization")}
                          />
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("organization-type-0")}
                          id="organization-type"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setOrganizationType(e.target.value);
                            },
                            value: organizationType,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("name")}
                          id="organization-name"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setName(e.target.value);
                            },
                            value: name,
                          }}
                          error={nameError.length > 0}
                          helperText={nameError}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("users-limit-1")}
                          id="users-limit"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setUsersLimit(e.target.value);
                            },
                            value: usersLimit,
                          }}
                          error={usersLimitError.length > 0}
                          helperText={usersLimitError}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("email-address-1")}
                          id="email-address"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "email",
                            onChange: (e) => {
                              setEmail(e.target.value);
                            },
                            value: email,
                          }}
                          error={emailError.length > 0}
                          helperText={emailError}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("paymail-handle")}
                          id="paymail-handle"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setPaymailHandle(e.target.value);
                            },
                            value: paymailHandle,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("subdomains")}
                          id="subdomains"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setSubdomains(e.target.value);
                            },
                            value: subdomains,
                          }}
                          error={subdomainsError.length > 0}
                          helperText={subdomainsError}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("number")}
                          id="organization-number"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setNumber(e.target.value);
                            },
                            value: number,
                          }}
                          error={numberError.length > 0}
                          helperText={numberError}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("vat-number")}
                          id="organization-vat-number"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setVatNumber(e.target.value);
                            },
                            value: vatNumber,
                          }}
                          error={vatNumberError.length > 0}
                          helperText={vatNumberError}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText={t("vat-rate")}
                          id="organization-vat-rate"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => {
                              setVatRate(e.target.value);
                            },
                            value: vatRate,
                          }}
                          error={vatRateError.length > 0}
                          helperText={vatRateError}
                        />
                      </GridItem>
                    </GridContainer>
                  </React.Fragment>
                )}
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("address")}
                      id="organization-address"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setAddress(e.target.value);
                        },
                        value: address,
                      }}
                      error={addressError.length > 0}
                      helperText={addressError}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("city")}
                      id="organization-city"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCity(e.target.value);
                        },
                        value: city,
                      }}
                      error={cityError.length > 0}
                      helperText={cityError}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("postal-code")}
                      id="organization-postal-code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPostalCode(e.target.value);
                        },
                        value: postalCode,
                      }}
                      error={postalCodeError.length > 0}
                      helperText={postalCodeError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("country")}
                      id="organization-country"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setCountry(e.target.value);
                        },
                        value: country,
                      }}
                      error={countryError.length > 0}
                      helperText={countryError}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("contact-person")}
                      id="organization-contant-person"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setContactPerson(e.target.value);
                        },
                        value: contactPerson,
                      }}
                      error={contactPersonError.length > 0}
                      helperText={contactPersonError}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText={t("phone-number")}
                      id="organization-phone-number"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setPhoneNumber(e.target.value);
                        },
                        value: phoneNumber,
                      }}
                      error={phoneNumberError.length > 0}
                      helperText={phoneNumberError}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                      labelText={t("landing-page-uri")}
                      id="landing-page-uri"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => {
                          setLandingPageUri(e.target.value);
                        },
                        value: landingPageUri,
                      }}
                      error={landingPageUriError.length > 0}
                      helperText={landingPageUriError}
                    />
                  </GridItem>
                </GridContainer>
                <Button
                  color="primary"
                  type="submit"
                  className={classes.updateProfileButton}
                >
                  {t("edit")}
                </Button>
              </form>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {!query.superuser && (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <LibraryBooksIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{"Metadata"}</h4>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={organizationProfile.metadata || []}
                  filterable
                  columns={[
                    {
                      Header: t("name"),
                      accessor: "name",
                    },
                    {
                      Header: t("value"),
                      accessor: "value",
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop={false}
                  showPaginationBottom
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {query.superuser && (
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="turq" icon style={{ display: "inline-flex" }}>
                <CardIcon color="turq">
                  <LibraryBooksIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>{"Metadata"}</h4>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    color="primary"
                    style={{ marginTop: "20px" }}
                    round
                    onClick={() => {
                      setShowMetadataModal(true);
                    }}
                  >
                    {t("add")}
                  </Button>
                </div>
              </CardHeader>
              <CardBody>
                <ReactTable
                  data={handleReturnMetadata(
                    organizationProfile.metadata || []
                  )}
                  filterable
                  columns={[
                    {
                      Header: t("name"),
                      accessor: "name",
                    },
                    {
                      Header: t("value"),
                      accessor: "value",
                    },
                    {
                      Header: t("actions"),
                      accessor: "actions",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={5}
                  showPaginationTop={false}
                  showPaginationBottom
                  className="-striped -highlight"
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      {query.superuser && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="turq" icon>
                <CardIcon color="turq">
                  <DeleteOutlineIcon style={{ color: roseColor[0] }} />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("delete-profile-0")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer
                  className={`${classes.description} ${classes.marginIn} ${classes.cardMarginBottom}`}
                >
                  <GridItem xs={12}>
                    <h5>{t("permanently-delete-profile")}</h5>
                  </GridItem>
                </GridContainer>
                <Button
                  style={{ color: grayColor[1] }}
                  simple
                  className={classes.updateProfileButton}
                  onClick={showDeleteProfile}
                >
                  {t("delete")}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
      <Snackbars
        place="bc"
        color="danger"
        icon={AddAlert}
        message={errorMessage}
        open={errorMessage.length > 0}
        closeNotification={() => setErrorMessage("")}
        close
      />
      <Snackbars
        place="bc"
        color="success"
        icon={AddAlert}
        message={successMessage}
        open={successMessage.length > 0}
        closeNotification={() => setSuccessMessage("")}
        close
      />
      {alert}
    </div>
  );
};

OrganizationProfile.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default OrganizationProfile;
